<template>
  <div>
     <button v-if="false"
                            class="btn text-white mx-2 px-4 py-2"
                            style="background: #4d1b7e; border-radius: 50px"
                            @click="openModal()"
                            
                          >
                            <span >Result Product</span>
              
                          </button>
    <Modal ref="resultScoreModal" id="resultScoreModal" title="<span class='mx-2'>Result Score</span>">
      <div class="" style="min-height:200px; max-height:400px; overflow-y:auto; overflow-x:hidden">
        <div class="" v-if="isLoadFirstTime">
          <div v-for="index in 5" :key="index" class="integrationImgae mt-2 bg-preload"
            style="height: 30px; width: 100%; border-radius: 14px">
            <div class="into-preload"></div>
          </div>
        </div>
        <div
 v-else-if="comparisons.length<=0"
  class="bg-white w-100"
  style="border: 1px dashed rgb(255, 162, 1); padding: 4%">
  <div class="row">
    <div
     
      class="col-md-12 d-flex justify-content-around align-items-center">
      <p
       
        class="text-left"
        style="color: rgb(77, 73, 80); font-weight: 400">
        You have not created any result score yet. <br />
        Create now and start increasing your sales!
      </p>
      <button
       @click="pushMore()"
        type="button"
        class="outline-none outline-none border-0 px-3 py-2 text-white mx-2"
        style="border-radius: 6px; background: rgb(255, 162, 1)">
        Create First Result Score
      </button>
    </div>
  </div>
</div>
        <div v-else class="d-flex justify-content-between mx-3 mt-3 border rounded p-3 position-relative"
          v-for="(compare, index) in comparisons" :key="index">
          <div class="xmark-btn" @click="remove(index)"><i class="fa-solid fa-xmark w-100"></i></div>
          <div class="d-flex flex-wrap align-items-center " style="gap:8px">
            <div class="">If</div>
            <div class=""><Select v-model="compare.comparable_value" class="form-control">
                <option disabled value="">Select Property</option>
                <option value="score">Score</option>
              </Select></div>
            <div class=""><select @change="inputChange(index)" v-model="comparisons[index].operator"
                class="form-control">
                <option disabled value="">Select Property</option>
                <option v-for="property in logic" :key="property.name" :value="property.symbol">{{ property.name }}
                </option>
              </select></div>
            <div style="width: 40%;">
              <div class="d-flex align-items-center ScoreDiv">

                <!-- <p
                                   <div class="d-flex" comparisons[index].operator=='range'>
                                       class="m-0 p-0 ScoreNum ">{{ parseInt(option.option_detail) }}</p> -->
                <input v-model.number="comparisons[index].target_value" v-if="comparisons[index].operator != 'range'"
                  type="number" class="form-control" placeholder="Score">
                <!-- <input  type="number" class="form-control" placeholder="Score"> -->
                <div class="d-flex" style="gap:5px" v-else>
                  <input v-model.number="comparisons[index].target_value[0]" type="number" class="form-control w-50"
                    placeholder="Min">
                  <input v-model.number="comparisons[index].target_value[1]" type="number" class="form-control w-50"
                    placeholder="Max">
                </div>
              </div>
            </div>
            <div class="col-12 p-0">
              <multiselect  v-model="comparisons[index].products" :optionHeight="5" id="ajax" label="title"
                :maxHeight="200" track-by="id" placeholder="Type to search" open-direction="bottom" :options="products"
                :multiple="true" :searchable="true" :loading="isLoading" :limit="3" option :internal-search="false"
                :clear-on-select="false" :close-on-select="false" :limit-text="limitText" :max-height="600"
                :show-no-results="false" :hide-selected="true" @search-change="handleProductSearchDebounced">
             
            
      
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-3 justify-content-between" v-if="comparisons.length>0&&(!isLoadFirstTime)">

        <Button @click="pushMore()">More</Button>
        <Button :isLoading="isLoadSubmit" @click="storeQuizScore()">Store</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { debounce } from "lodash";
import Multiselect from "vue-multiselect";
import axios from "axios"
import Modal from '../../Dashboard/Layout/Modal.vue'
import Button from '../../Dashboard/Layout/Button.vue'
import { mapGetters } from "vuex";
export default {
  components: {
    Modal,
    Button,
    Multiselect
  },
  data() {
    return {
      comparisons: [
        {
          
        }],
        isLoadSubmit:false,
      isLoadFirstTime: true,
      isLoading: false,
      products: [],
      selectedProducts: [],
      logic: [{
        name: 'Equal',
        symbol: '==',
        onlyFor: ['score']
      },
      {
        name: 'Less than',
        symbol: '<',
        onlyFor: ['score']
      },
      {
        name: 'Less than or equal',
        symbol: '<=',
        onlyFor: ['score']
      },
      {
        name: 'Greater than',
        symbol: '>',
        onlyFor: ['score']
      },
      {
        name: 'Greater than or equal',
        symbol: '>=',
        onlyFor: ['score']
      },
      {
        name: 'Range',
        symbol: 'range',
        onlyFor: ['score']
      }
      ]
    }
  },
  computed: {
    ...mapGetters(["getQuizID"]),
    quizId(){
      return  this.getQuizID ? this.getQuizID : localStorage.getItem('QuizID')
    },
    validationCheck(){
    let isInvalid =false
    this.comparisons.forEach(compare=>{
      if(compare.comparable_value==''||compare.operator==''||compare.target_value==''||compare.target_value.length==0||compare.products.length==0)
      {
        isInvalid=true
      }
    })
    return isInvalid
    },
    getComparisons() {
      return [...this.comparisons]
    },
  },
  methods: {
    toast(text){
        this.$toasted.show(text, {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },
    inputChange(index) {

      if (this.comparisons[index].operator == 'range') {
        this.comparisons[index].target_value = []

      }
      else {

        this.comparisons[index].target_value = ''
      }
    },
    remove(index) {
      this.comparisons.splice(index, 1)
      if(index==0)
      this.storeQuizScore()
    },
    async getComparisonsBackend() {
     
      this.isLoadSubmit=true;
      await axios.get('/quiz/'+this.quizId+'/result-comparison').then((response) => {
       
        this.comparisons = response.data.data
     this.comparisons.forEach(compare=>{
      this.products=[...new Set([...compare.products,...this.products])]
     })
      }).catch(error => { console.log(error) })
       this.isLoadSubmit=false;
    },
    pushMore() {
      this.comparisons.push({

        "comparable_value": "score",
        "operator": "",
        "target_value": [
          22,
          2
        ],
        "product": [

        ]
      }
      )
    },
    //  handleInput(value,index) {
    //       // Update selectedOptions with just the IDs
    //       // this.comparisons[index].product_ids = value.map(option => option.id);
    //     },
    handleProductSearchDebounced: debounce(function (query) {
      this.getProducts(query)
    }, 500),
    clearAll() {
      this.selectedProducts = []
    },
    async storeQuizScore() {
      try {
         if(this.validationCheck)
      {
        this.toast('Please fill all the fields.');
        return;
      }
        this.isLoadSubmit=true;

        const comparison = Array.from(this.getComparisons).map(comp => ({
          ...comp,
          product_ids: comp.products.map(subProduct => subProduct.id)
        }));
        const response = await axios.post('/quiz/'+this.quizId+'/result-comparison', { comparisons: comparison })
       if(response.data.status)
       {
        this.toast('Result score updated successfully!')
       }
       else{
         this.toast('Something went wrong')
       }
      } catch (error) {
        console.log(error)
        this.toast('Something went wrong')
      }
      this.isLoadSubmit=false;
    },

    limitText(count) {
      return `and ${count} other products`
    },
    async getProducts(query) {
      this.isLoading = true
      await axios.post('/loadSavedProducts', { title: query}).then(response => {
        this.products = [...new Set([...response.data.data.data.map(product=>({id:product.id,title:product.title})), ...this.products])]
      }).catch(error => {
        console.log(error.response)
      })
      this.isLoading = false
    },
    async openModal() {
      this.isLoadFirstTime = true
      this.$refs.resultScoreModal.open()
      await this.getProducts();
      await this.getComparisonsBackend()
      this.isLoadFirstTime = false
    }
  },
}
</script>
<style>
.product-match-select:focus {
  /* border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important; */
}

.product-match-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.multiselect__option--highlight {
  background: #b3afb626 !important;
  color: black !important;
}

.multiselect__option--selected {
  background: #b3afb6 !important;
  color: black !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #b3afb626 !important;
  content: attr(data-deselect);
  color: black !important;
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #f4f3f4 !important;
  color: black !important;
}

.xmark-btn {
  position: absolute;
  cursor: pointer;
  top: -10px;
  height: 20px;
  width: 20px;
  line-height: 1;
  text-align: center;
  border-radius: 50%;
  background: rgb(215, 215, 215);
  right: -8px;
  display: flex;
  align-items: center;
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: inherit !important;
  background: inherit;
}

.multiselect__tag {
  background: #613c86 !important;
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;

  background: #fff;
  font-size: 14px;
}

.multiselect__tag-icon:after {
  color: #fbfbfb !important;
}
</style>
<style scoped>
.condition-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #1F1F28;
}

.ScoreDiv .ScoreBtn {
  background: #4d1b7e;
  color: #ffffff;

}

.ScoreDiv {
  border-radius: 2px;
}

.ScoreDiv .ScoreNum {
  color: #18191C;
  font-weight: 600;
  min-width: 40px;
  width: 50px;
  max-width: 60px;
  text-align: center;
}
</style>